import React from "react";
import Select from "react-select";
import { countryCodes } from "../../dummyData";

// Prepare options for react-select
const countryOptions = countryCodes.map((country) => ({
  value: country.code,
  label: `${country.flag} ${country.code}`,
}));

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "55px", // Same height as the input
    borderRadius: "0.25rem", // Bootstrap-style border radius
    // border: state.isFocused ? "2px solid #0c0f11" : "2px solid #0c0f11",
    "&:hover": {
      borderColor: "#0c0f11",
    },
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: "0.375rem 0.75rem", // Same padding as the input field
  }),
  option: (provided) => ({
    ...provided,
    padding: "0.375rem 0.75rem", // Consistent padding for options
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0.375rem", // Same size as the right padding of the input
  }),
  indicatorSeparator: () => ({
    display: "none", // Hide the vertical line between dropdown and input field
  }),
};

const PhoneInput = ({ label, register, errors, setValue }) => {
  return (
    <div className="mb-3">
      <label htmlFor="phone" className="form-label fw500 dark-color">
        {label}
      </label>
      <div className="input-group" style={{ height: "55px" }}>
        {/* Country code select box using react-select */}
        <Select
          options={countryOptions}
          onChange={(selectedOption) =>
            setValue("countryCode", selectedOption.value)
          } // Set value for react-hook-form
          placeholder="Code"
          styles={customStyles} // Apply custom styles to match input
          components={{ IndicatorSeparator: () => null }} // Remove the separator line between the input and arrow
        />

        {/* Mobile number input */}
        <input
          id="mobile"
          {...register("mobile", {
            required: "Mobile is required",
            maxLength: {
              value: 10,
              message: "Mobile number must not be more than 10 digits",
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Mobile number must be digits only",
            },
          })}
          type="tel"
          className="form-control"
          placeholder="Enter your mobile number"
        />
      </div>

      {/* Error handling for country code */}
      {errors.countryCode && (
        <p style={{ color: "red" }}>{errors.countryCode.message}</p>
      )}

      {/* Error handling for mobile number */}
      {errors.mobile && <p style={{ color: "red" }}>{errors.mobile.message}</p>}
    </div>
  );
};

export default PhoneInput;
