export const baseApiURL = process.env.REACT_APP_API_BASE_URL;
// Auth Api Url
export const RegisterUserURL = "/tsp/api/1.0/user/signup";
export const LoginUserURL = "/tsp/api/1.0/user/sign-in";
export const GoogleLoginURL = "/tsp/api/1.0/user/google/callback";
export const VerifyOtpURL = "/tsp/api/1.0/user/verify";
export const ForgetPasswordURL = "/tsp/api/1.0/user/process-forget-password";
export const ResetPasswordURL = "/tsp/api/1.0/user/execute-forget-password";
export const PlansURL = "/tsp/api/1.0/plans";
export const MobileSignInURL = "/tsp/api/1.0/user/mobile-sign-in";
export const MobileSignInVerifyURL = "/tsp/api/1.0/user/mobile-sign-in-verify";
export const ContactUsUrl = "/tsp/api/1.0/add-query";

// User Api Url
export const UserDetailsURL = "/tsp/api/1.0/user";
export const UpdateUserURL = "/tsp/api/1.0/user";
export const UpdateUserEmailStartURL = "/tsp/api/1.0/user/update-email-start";
export const UpdateUserEmailVerifyURL = "/tsp/api/1.0/user/update-email-verify";
export const UpdateUserMobileStartURL = "/tsp/api/1.0/user/update-mobile-start";
export const UpdateUserMobileVerifyURL =
  "/tsp/api/1.0/user/update-mobile-verify";
export const AddPortfolioURL = "/tsp/api/1.0/user/portfolio";
export const GetUserPortfoliosURL = "/tsp/api/1.0/user/portfolio";
export const NotificationListURL = "/tsp/api/1.0/user-notifications";
export const UnseenNotificationListURL = "/tsp/api/1.0/unseen-notifications";
export const ResendOtpURL = "/tsp/api/1.0/user/resend-signup-otp";
export const InvitationListURL = "/tsp/api/1.0/invitation-list";
export const DeleteUserCategoryURL = "/tsp/api/1.0/user/delete-category";
export const ResendMobileOtpURL = "/tsp/api/1.0/user/resend-mobile-otp";

// Jobs

export const JobListURL = "/tsp/api/1.0/job-list";
export const JobDetailURL = "/tsp/api/1.0/job-detail";
export const JobSelectedURL = "/tsp/api/1.0/job/apply/selected";
export const JobUpdateChosenURL = "/tsp/api/1.0/job/update-chosen";
export const JobStatusUpdateURL = "/tsp/api/1.0/job/status-update";
export const JobProposalStatusUpdateURL =
  "/tsp/api/1.0/job-proposal/status-update";
export const InvitePhotographerURL = "/tsp/api/1.0/job/invite-photographer";
export const UpdateInviteURL = "/tsp/api/1.0/job/update-invite";
export const DeleteInviteURL = "/tsp/api/1.0/job/delete-invite";

// JobPoster
export const CreateJobURL = "/tsp/api/1.0/job";
export const MyCreatedJobURL = "/tsp/api/1.0/job/mine";
export const MyJobListURL = "/tsp/api/1.0/my-jobs";
export const MyJobDetailsURL = "/tsp/api/1.0/my-job-details";
export const ProposalListURL = "/tsp/api/1.0/job-proposal/list";
export const ReportReviewURL = "/tsp/api/1.0/job/report-review";
export const ReviewJobPosterURL = "/tsp/api/1.0/job/review-job-poster";
export const JobListWithoutInviteURL = "tsp/api/1.0/job/invite-list";
export const DeleteJobURL = "tsp/api/1.0/job/status-update";

// Photographers
export const PhotographersListURL = "/tsp/api/1.0/photographers-list";
export const PhotographerDetailsURL = "/tsp/api/1.0/photographer-detail";
export const SendProposalURL = "/tsp/api/1.0/job/apply";
export const MyAppliedJobURL = "/tsp/api/1.0/job/applied-list";
export const MyRunningJobsListURL = "/tsp/api/1.0/job/chosen-list";
export const MyActivePlanURL = "/tsp/api/1.0/user-plan";

//  file Upload

export const UploadURL = "/tsp/api/1.0/upload";

// Chat ---
export const CreateChatURL = "/tsp/api/1.0/chat";
export const GetAllChatURL = "/tsp/api/1.0/chat/list";
export const GetOneChatDetailsURL = "/tsp/api/1.0/chat/chat-details";
export const GetChatCountURL = "/tsp/api/1.0/chat/n-count";

// Admin
export const UserListURL = "/tsp/api/1.0/admin/user-list";
export const UserDetailURL = "/tsp/api/1.0/admin/user-detail";
export const UserProfileStatusURL = "/tsp/api/1.0/admin/user-profile-status";
export const RatingReportURL = "/tsp/api/1.0/admin/rating-report";
export const UpdateRatingReportURL =
  "/tsp/api/1.0/admin/update-rating-report-status";
export const TicketListURL = "/tsp/api/1.0/admin/ticket-list";
export const TicketDetailURL = "/tsp/api/1.0/admin/ticket-detail";
export const UpdateTicketURL = "/tsp/api/1.0/admin/update-ticket";
export const ContentListURL = "/tsp/api/1.0/admin/content-list";
export const UpdateContentURL = "/tsp/api/1.0/admin/update-content";
export const JobListsURL = "/tsp/api/1.0/admin/job-list";
export const JobDetailsURL = "/tsp/api/1.0/admin/job-details";
export const UpdateJobStatusURL = "/tsp/api/1.0/admin/update-job-status";
export const ReportListsURL = "/tsp/api/1.0/admin/reported-reviews";
export const ReportDetailsURL = "/tsp/api/1.0/admin/job-details";
export const UpdateReportStatusURL = "/tsp/api/1.0/admin/update-review-status";
export const InsuranceListURL = "/tsp/api/1.0/admin/insurance-user-list";
export const UpdateInsuranceStatusURL =
  "/tsp/api/1.0/admin/verify-user-insurance";
export const QueryListingURL = "/tsp/api/1.0/admin/list-queries";
export const QueryReplyURL = "/tsp/api/1.0/admin/reply-query";

// Stripe
export const CreateCustomerURL = "/tsp/api/1.0/payment/create-customer";
export const CreateSubscriptionURL = "/tsp/api/1.0/payment/create-subscription";
export const UpdateSubscriptionURL = "/tsp/api/1.0/payment/update-subscription";
export const CancelSubscriptionURL = "/tsp/api/1.0/payment/cancel-subscription";
export const CreateCustomerPortalURL =
  "/tsp/api/1.0/payment/create-customer-portal";
export const HandleWebhookURL = "/tsp/api/1.0/payment/handle-webhook";
