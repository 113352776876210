import React, { useEffect, useRef, useState } from "react";
import { getOneChat } from "../../../../API/ChatApi";
import { getUserInfo } from "../../../../common/getUserInfo";
import { Status } from "../../../../constants/constant";
import { ErrorToaster } from "../../../../shared/Toasters/toaster";
import { io } from "socket.io-client";
import { formatDateWithTime } from "../../../../utils/Dates";
import Loader from "../../../../utils/Loader";

const socket = io("https://api.topshotphotographer.com/");

const ChatBox = ({ chatId, userData }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [status, setStatus] = useState("");
  const [totalMessagesHeight, setTotalMessagesHeight] = useState(0);
  const userInfo = getUserInfo();

  const chatContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const chatListRef = useRef(null);

  useEffect(() => {
    // Function to scroll to the bottom of the chat box
    const scrollToBottom = () => {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    };

    scrollToBottom();
  }, [chatMessages]);

  useEffect(() => {
    const calculateTotalHeight = () => {
      const messages = document.querySelectorAll(".chatting_content li");
      const totalHeight = Array.from(messages).reduce(
        (acc, curr) => acc + curr.offsetHeight,
        0
      );
      setTotalMessagesHeight(totalHeight);
    };

    calculateTotalHeight();
  }, [chatMessages]);

  useEffect(() => {
    // Subscribe to the new chat room when chatId changes
    socket.emit("subscribe", chatId);

    // Listen for new messages in the subscribed chat room
    socket.on("conversations", (data) => {
      setChatMessages((prevMessages) => [...prevMessages, data]);
    });

    // Clear previous event listeners when component unmounts or chat changes
    return () => {
      socket.off("conversations");
    };
  }, [chatId]);

  const sendMessage = (message) => {
    socket.emit("message", {
      room: message?.chatId,
      message: message,
    });
  };

  const getCurrentChatDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getOneChat(chatId);
      setChatMessages(res);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  useEffect(() => {
    getCurrentChatDetails();
  }, [chatId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = e.target.elements.message.value;
    const formData = {
      senderId: userInfo?._id,
      message: message,
      chatId: chatId,
      rId:
        chatMessages[0]?.receiverId === userInfo?._id
          ? chatMessages[0]?.senderId
          : chatMessages[0]?.receiverId,
      jobId: chatMessages[0]?.jobId,
      attachment: "",
    };
    sendMessage(formData);
    e.target.elements.message.value = "";
  };
  console.log("userData", userData);

  return (
    <div className="col-lg-6 col-xl-7 col-xxl-8">
      {status === Status.PENDING ? (
        <div style={{ marginRight: "150px", marginTop: "100px" }}>
          <Loader />
        </div>
      ) : (
        <div className="message_container mt30-md" ref={chatListRef}>
          <div
            className="user_heading px-0 mx30"
            style={{ padding: "10px 0px 0px" }}
          >
            <div className="wrap">
              <img
                className="rounded-circle mx-auto"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
                src={
                  userData
                    ? userData[
                        userInfo?.userType === "photographer"
                          ? chatMessages[0]?.senderId
                          : chatMessages[0]?.receiverId
                      ]?.profilePicture ||
                      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                    : "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                }
                alt="ms1.png"
              />
              <div className="meta d-sm-flex justify-content-sm-between align-items-center p5">
                <div className="authors">
                  <h6 className="name mb-0">
                    {userInfo?._id === chatMessages[0]?.receiverId
                      ? chatMessages[0]?.senderName
                      : chatMessages[0]?.receiverName}
                  </h6>
                  <p className="preview">Active</p>
                  {/* <p className="preview">{chatMessages[0]?.chatId}</p> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="inbox_chatting_box">
            <ul
              className="chatting_content"
              style={{ maxHeight: '500px' }}
            >
              {chatMessages
                ?.filter((chat) => chat?.chatId === chatId)
                ?.map((msg, i) => (
                  <li
                    key={i}
                    className={
                      msg.senderId !== userInfo?._id
                        ? 'sent float-start'
                        : 'reply float-end'
                    }>
                    <div
                      className={
                        msg.senderId !== userInfo?._id
                          ? 'd-flex align-items-center mb15'
                          : 'd-flex align-items-center justify-content-end mb15'
                      }
                      style={
                        msg.senderId !== userInfo?._id
                          ? { marginRight: '250px' }
                          : { marginLeft: '280px' }
                      }>
                      
                    </div>
                    <p
                      style={
                        msg.senderId !== userInfo?._id
                          ? {
                              padding: '5px 15px',
                              backgroundColor: 'rgb(183 244 203)',
                              position: 'relative',
                            }
                          : {
                              padding: '8px 15px',
                              backgroundColor: 'rgb(210 247 224)',
                              position: 'relative',
                              // textAlign: 'left',
                            }
                      }>
                      {msg?.message}
                    </p>
                    <small
                      className="timeStamp"
                      style={
                        msg.senderId !== userInfo?._id
                          ? {
                              bottom: '2px',
                              fontSize: '11px',
                              color: 'grey', // Set timestamp color as needed
                            }
                          : {
                              bottom: '2px',
                              fontSize: '11px',
                              marginLeft: '390px',
                              color: 'grey',
                              textAlign: 'left',
                              width: 'fit-content',
                            }
                      }>
                      {formatTimeFromNow(msg?.date)}
                    </small>
                  </li>
                ))}
            </ul>
          </div> */}
          <div className="inbox_chatting_box">
            <ul className="chatting_content">
              {chatMessages
                ?.filter((chat) => chat?.chatId === chatId)
                ?.map((msg, i) => (
                  <li
                    key={i}
                    className={
                      msg.senderId !== userInfo?._id
                        ? "sent float-start"
                        : "reply float-end"
                    }
                    style={{
                      marginBottom:
                        msg.senderId !== userInfo?._id ? "-45px" : "-30px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems:
                          msg.senderId !== userInfo?._id
                            ? "flex-start"
                            : "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex align-items-center mb15">
                        {msg.senderId !== userInfo?._id ? (
                          <>
                            <img
                              className={`img-fluid rounded-circle ${
                                msg.senderId !== userInfo?._id
                                  ? "align-self-start"
                                  : "align-self-end"
                              }  mr10`}
                              src={
                                userData
                                  ? userData[
                                      userInfo?.userType === "photographer"
                                        ? chatMessages[0]?.senderId
                                        : chatMessages[0]?.receiverId
                                    ]?.profilePicture
                                  : "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                              }
                              alt="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                // marginLeft: '5px',
                              }}
                            />
                            <div className="title fz15">
                              {userInfo?._id === chatMessages[0]?.receiverId
                                ? chatMessages[0]?.senderName
                                : chatMessages[0]?.receiverName}{" "}
                              <small className="ml10"></small>
                            </div>
                          </>
                        ) : (
                          <div className="title fz15">
                            You<small className="ml10"></small>
                          </div>
                        )}
                      </div>
                      <p
                        style={{
                          padding: "8px 15px",
                          backgroundColor:
                            msg.senderId !== userInfo?._id
                              ? "rgb(183 244 203)"
                              : "rgb(210 247 224)",
                          borderRadius:
                            msg.senderId !== userInfo?._id
                              ? "15px 15px 15px 0"
                              : "15px 15px 0 15px",
                          maxWidth: msg?.message?.length <= 25 ? "100%" : "70%",
                          wordBreak: "break-word", // Allow long words to be broken and wrap onto the next line
                          whiteSpace: "pre-wrap", // Maximum width for messages
                          textAlign: "left",
                        }}
                      >
                        {msg?.message}
                      </p>
                      <small
                        className="timeStamp"
                        style={{
                          fontSize: "11px",
                          color: "grey",
                          // alignSelf: 'flex-end',
                          marginTop: "3px",
                        }}
                      >
                        {formatDateWithTime(msg?.date || new Date())}
                      </small>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          {/* <div className="mi_text"> */}
          <div className="message_input">
            <form
              className="d-flex align-items-center"
              style={{ width: "85%" }}
              onSubmit={handleSubmit}
            >
              <div
                className="flex-grow-1"
                style={{ paddingTop: "3px", marginLeft: "1px" }}
              >
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Type a Message"
                  aria-label="Search"
                />
              </div>
              <div className="ml5">
                <button
                  className="btn ud-btn btn-thm"
                  style={{
                    height: "56px",
                    position: "absolute",
                    right: "0px",
                    top: "2px",
                  }}
                >
                  Send<i className="fal fa-arrow-right-long"></i>
                </button>
              </div>
            </form>
          </div>

          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default ChatBox;
