import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
// import { ErrorBoundary } from "react-error-boundary";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoadScript } from "@react-google-maps/api";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ErrorBoundary from "./components/Home/Errorboundary";

const container = document.getElementById("root");
const root = createRoot(container);

const stripePromise = loadStripe(
  "pk_test_51PstTIJIRMZRDZ1FK4IrAtWbCdHvEjgPDC5z6Q2azPOXZgeSyRJac6o1wxHj5a01GUwsTYWKCQhYyljOlmlvzDQu007iKhPbrz"
);

function MyFallbackComponent({ error, resetErrorBoundary }) {
  console.log("error", error);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
const libraries = ["places"];

root.render(
  <GoogleOAuthProvider clientId="845502742149-1f3366l32ddp3epf3rve3lgflvsfrucf.apps.googleusercontent.com">
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ErrorBoundary>
            <LoadScript
              googleMapsApiKey="AIzaSyCDdltG_NTmOZB_UnvB5EkSrZikPFcddlE" // Replace with your actual API key
              libraries={libraries}
            >
              <App />
            </LoadScript>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </Elements>
  </GoogleOAuthProvider>
);
