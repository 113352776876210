export const photographersList = [
  {
    id: 1,
    title: "Leslie Alexander",
    designation: "UI/UX Designer",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/fl-1.png",
    rating: 4.9,
    totalReviews: 595,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London",
    Rate: 90,
    jobSuccess: 98,
    images: [
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
    ],
  },
  {
    id: 2,
    title: "Guy Hawkins",
    designation: "FULL STACK DEVELOPER",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/fl-5.png",
    rating: 4.6,
    totalReviews: 634,
    skills: ["Figma", "Sketch", "HTML5", "JAVASCRIPT"],
    location: "London",
    Rate: 86,
    jobSuccess: 96,
    images: [
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
    ],
  },
  {
    id: 3,
    title: "Brooklyn Simmons",
    designation: "WEEDING PHOTOGRAPHER",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/fl-6.png",
    rating: 4.9,
    totalReviews: 595,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London",
    Rate: 90,
    jobSuccess: 98,
    images: [
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
    ],
  },
  {
    id: 4,
    title: "Robert Fox",
    designation: "UI/UX Designer",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/fl-4.png",
    rating: 4.9,
    totalReviews: 595,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London",
    Rate: 90,
    jobSuccess: 98,
    images: [
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
    ],
  },
  {
    id: 5,
    title: "Darlene Robertson",
    designation: "UI/UX Designer",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/fl-3.png",
    rating: 4.9,
    totalReviews: 595,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London",
    Rate: 90,
    jobSuccess: 98,
    images: [
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
    ],
  },
  {
    id: 6,
    title: "Leslie Alexander",
    designation: "UI/UX Designer",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/fl-2.png",
    rating: 4.9,
    totalReviews: 595,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London",
    Rate: 90,
    jobSuccess: 98,
    images: [
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/listings/g-4.jpg",
    ],
  },
];

export const jobList = [
  {
    id: 1,
    title: "Food Delivery Mobile App",
    description:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/client-1.png",
    proposal: 1,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London, UK",
    minRate: 90,
    maxRate: 150,
    createdAt: "2023-09-05T00:00:00.000Z",
  },
  {
    id: 2,
    title: "Food Delivery Mobile App",
    description:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/client-2.png",
    proposal: 4,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London, UK",
    minRate: 80,
    maxRate: 100,
    createdAt: "2023-09-07T00:00:00.000Z",
  },
  {
    id: 3,
    title: "Food Delivery Mobile App",
    description:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/client-3.png",
    proposal: 1,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London, UK",
    minRate: 90,
    maxRate: 130,
    createdAt: "2023-09-05T00:00:00.000Z",
  },
  {
    id: 4,
    title: "Food Delivery Mobile App",
    description:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/client-4.png",
    proposal: 1,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London, UK",
    minRate: 70,
    maxRate: 140,
    createdAt: "2023-09-05T00:00:00.000Z",
  },
  {
    id: 5,
    title: "Food Delivery Mobile App",
    description:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/client-5.png",
    proposal: 1,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London, UK",
    minRate: 60,
    maxRate: 90,
    createdAt: "2023-09-05T00:00:00.000Z",
  },
  {
    id: 6,
    title: "Food Delivery Mobile App",
    description:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
    profileImg:
      "https://creativelayers.net/themes/freeio-html/images/team/client-6.png",
    proposal: 1,
    skills: ["Figma", "Sketch", "HTML5"],
    location: "London, UK",
    minRate: 100,
    maxRate: 140,
    createdAt: "2023-09-05T00:00:00.000Z",
  },
];

export const images = [
  "https://img.freepik.com/free-photo/digital-painting-mountain-with-colorful-tree-foreground_1340-25699.jpg?size=626&ext=jpg&ga=GA1.1.1826414947.1700265600&semt=ais",
  "https://i.pinimg.com/736x/dd/97/3a/dd973ac116a977c8dd5296b0da504b8c.jpg",
  "/assets/images/Category-Images/Pet.JPG",
  "https://images.pexels.com/photos/1266810/pexels-photo-1266810.jpeg?cs=srgb&dl=pexels-simon-berger-1266810.jpg&fm=jpg",
  "https://t4.ftcdn.net/jpg/05/62/44/49/360_F_562444995_aiVFD8nbFTECt4zUEHFIsUDwyZkhNDDc.jpg",
  "https://media.istockphoto.com/id/515708494/photo/mahatma-gandhi-hall.jpg?s=612x612&w=0&k=20&c=0r0tu3VAbZdmf4RlwI3Q-xZjiMa4abSfsvnDxHVlGCo=",
  "https://th.bing.com/th/id/OIG.ey_KYrwhZnirAkSgDhmg",
  "https://hips.hearstapps.com/hmg-prod/images/cadillac-halo-concept-innerspace-001-64eca0400d1d7.jpg?crop=0.813xw:0.814xh;0.0289xw,0.140xh&resize=980:*",
  "https://cdn.pixabay.com/photo/2023/01/05/18/29/ai-generated-7699560_960_720.jpg",
  "https://plus.unsplash.com/premium_photo-1683910767532-3a25b821f7ae?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZXhwbG9yYXRpb258ZW58MHx8MHx8fDA%3D",
  "https://replicate.delivery/mgxm/0958ab0c-8d26-45f8-a5f1-a27a1f2259cc/baby.jpg",
  "https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg",
  "https://spaceplace.nasa.gov/gallery-sun/en/solar-flare.en.jpg",
  "https://1.bp.blogspot.com/-kK7Fxm7U9o0/YN0bSIwSLvI/AAAAAAAACFk/aF4EI7XU_ashruTzTIpifBfNzb4thUivACLcBGAsYHQ/w680/222.jpg",
  "https://static4.depositphotos.com/1003326/319/i/450/depositphotos_3191160-stock-photo-blurry-bright-background.jpg",
  "https://media.istockphoto.com/id/505239248/photo/humayun-tomb-new-delhi-india.jpg?s=612x612&w=0&k=20&c=UQTU6YOnVsSklzHi34cOhNW5AhsACDxKLiD9--T-3Kg=",
  "https://www.befunky.com/images/prismic/f5ca4181-01da-4237-92bf-b6938359503e_hero-blur-image-5.jpg?auto=avif,webp&format=jpg&width=896",
  "https://th.bing.com/th/id/OIG.FP1kII1hFfcp.7XWtqHt",
  "https://img.freepik.com/free-photo/aerial-beautiful-shot-seashore-with-hills-background-sunset_181624-24143.jpg",
  "https://cdn.esawebb.org/archives/images/screen/weic2218a.jpg",
];

export const categoryImg = [
  {
    id: 1,
    category: "Advertising and Product",
    img: "/assets/images/Category-Images/Advertising_Product.JPG",
  },
  {
    id: 2,
    category: "Fashion and Beauty",
    img: "/assets/images/Category-Images/Modeling_Beauty.JPG",
  },
  {
    id: 3,
    category: "Wedding",
    img: "/assets/images/Category-Images/wedding_new.jpg",
  },
  {
    id: 4,
    category: "Engagement",
    img: "/assets/images/Category-Images/Engagement.JPG",
  },
  {
    id: 5,
    category: "Event",
    img: "/assets/images/Category-Images/_Event.JPG",
  },
  {
    id: 6,
    category: "Portraiture",
    img: "/assets/images/Category-Images/Portraiture.jpg",
  },
  {
    id: 7,
    category: "Headshot",
    img: "/assets/images/Category-Images/image_head.png",
  },
  {
    id: 8,
    category: "Maternity",
    img: "/assets/images/Category-Images/Maternity.jpg",
  },
  {
    id: 9,
    category: "Newborn",
    img: "/assets/images/Category-Images/New_Born.jpg",
  },
  {
    id: 10,
    category: "Family",
    img: "/assets/images/Category-Images/Family_Kids.JPG",
  },
  // {
  //   id: 13,
  //   category: 'IMG_1808',
  //   img: '/assets/images/Category-Images/IMG_1808.JPG',
  // },
  // {
  //   id: 14,
  //   category: 'IMG_1819',
  //   img: '/assets/images/Category-Images/IMG_1819.PNG',
  // },
  // {
  //   id: 5,
  //   category: 'Maternity',
  //   img: '/assets/images/Category-Images/_Maternity.JPG',
  // },
  {
    id: 11,
    category: "Boudoir",
    img: "/assets/images/Category-Images/Boudoir.JPG",
  },
  {
    id: 12,
    category: "Pet",
    img: "/assets/images/Category-Images/Pet_min_2.jpg",
  },
  {
    id: 13,
    category: "Sport and Action",
    img: "/assets/images/Category-Images/Sport&Action.jpg",
  },
  {
    id: 14,
    category: "Architecture and Real Estate",
    img: "/assets/images/Category-Images/Architecture&ReaLEstate.jpg",
  },
  {
    id: 15,
    category: "Aerial/Drone",
    img: "/assets/images/Category-Images/Aerial_Drone.jpg",
  },
  {
    id: 16,
    category: "Food",
    img: "/assets/images/Category-Images/Food.JPG",
  },
  {
    id: 17,
    category: "Travel",
    img: "/assets/images/Category-Images/Travel.jpg",
  },
];

// [
//   '/assets/images/Category-Images/Pet.JPG',
//   '/assets/images/Category-Images/_Concert.JPG',
//   '/assets/images/Category-Images/_Event.JPG',
//   '/assets/images/Category-Images/_Headshots.JPG',
//   '/assets/images/Category-Images/_Maternity.JPG',
//   '/assets/images/Category-Images/Advertising_Lifestyle.JPG',
//   '/assets/images/Category-Images/Advertising_Product.JPG',
//   '/assets/images/Category-Images/Architectural.JPG',
//   '/assets/images/Category-Images/Boudoir.JPG',
//   '/assets/images/Category-Images/Engagement.JPG',
//   '/assets/images/Category-Images/Family_Kids.JPG',
//   '/assets/images/Category-Images/Food.JPG',
//   '/assets/images/Category-Images/IMG_1808.JPG',
//   '/assets/images/Category-Images/IMG_1819.PNG',
//   '/assets/images/Category-Images/Modeling_Beauty.JPG',
//   '/assets/images/Category-Images/Musicians.JPG',
//   '/assets/images/Category-Images/SportsandAction.PNG',
//   '/assets/images/Category-Images/Wedding.JPG',
//   '/assets/images/Category-Images/Food.JPG',
// ];

export const photographerImages = [
  "/assets/images/home-photographers/photographer-1.jpg",
  "/assets/images/home-photographers/photographer-2.jpg",
  "/assets/images/home-photographers/photographer-3.jpg",
  "/assets/images/home-photographers/photographer-4.jpg",
  "/assets/images/home-photographers/photographer-5.jpg",
  "/assets/images/home-photographers/photographer-6.jpg",
  "/assets/images/home-photographers/photographer-7.jpg",
  "/assets/images/home-photographers/photographer-8.jpg",
];

export const categories = [
  "Advertising and Product",
  "Fashion and Beauty",
  "Wedding",
  "Engagement",
  "Event",
  "Portraiture",
  "Headshot",
  "Maternity",
  "Newborn",
  "Family",
  "Boudoir",
  "Pet",
  "Sport and Action",
  "Architecture and Real Estate",
  "Aerial/Drone",
  "Food",
  "Travel",
];

export const levels = ["Entry", "Intermediate", "Expert"];

export const portfolioData = [
  {
    category: "Concert",
    imageUrls: [
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
    ],
  },
  {
    category: "Engagement",
    imageUrls: [
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
    ],
  },
  {
    category: "Events",
    imageUrls: [
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
    ],
  },
  {
    category: "HeadShots",
    imageUrls: [
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
    ],
  },
  {
    category: "Maternity",
    imageUrls: [
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-1.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-2.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-3.jpg",
      "https://creativelayers.net/themes/freeio-html/images/gallery/g-4.jpg",
    ],
  },
];

export const subscriptionPlanList = [
  {
    id: 1,
    planName: "Free Plan",
    monthlyPrice: 0,
    yearlyPrice: 0,
    features: [
      "Apply for up to 5 jobs per month.",
      "Choose 1 category to display your work.",
      "Feature up to 10 photos in the selected category.",
    ],
    isActive: true,
  },
  {
    id: 2,
    planName: "Basic Plan",
    monthlyPrice: 5,
    yearlyPrice: 48,
    features: [
      "Apply for up to 10 jobs per month.",
      "Select up to 2 categories to showcase your work.",
      "Display up to 20 photos in each category.",
    ],
  },
  {
    id: 3,
    planName: "Premium Plan",
    monthlyPrice: 10,
    yearlyPrice: 96,
    features: [
      "Apply for up to 20 jobs per month.",
      "Choose up to 4 categories to showcase your work.",
      "Showcase up to 20 photos in each category.",
      "Upload 30 seconds video In the profile",
    ],
  },
  {
    id: 4,
    planName: "Enterprise Plan",
    monthlyPrice: 20,
    yearlyPrice: 192,
    features: [
      "Apply for up to 60 jobs per month.",
      "Highlight your work across all available categories.",
      "Feature up to 20 photos in each category.",
      "Upload 30 seconds video In the profile",
    ],
  },
];

export const reportReasons = [
  "Inappropriate Content",
  "False Information",
  "Spam or Advertising",
  "Personal Attack",
  "Irrelevant Content",
  "Other",
];

export const transactions = [
  {
    id: 1,
    user: "John Doe",
    email: "john.doe@example.com",
    subscriptionType: "Premium",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    transactionDate: "2024-01-20T12:30:00.000Z",
    transactionAmount: "$49.99",
    transactionStatus: "Successful",
    subscriptionStatus: "Active",
    paymentStatus: "Paid",
  },
  {
    id: 2,
    user: "Jane Smith",
    email: "jane.smith@example.com",
    subscriptionType: "Basic",
    startDate: "2024-02-15",
    endDate: "2024-03-15",
    transactionDate: "2024-01-20T14:45:00.000Z",
    transactionAmount: "$19.99",
    transactionStatus: "Failed",
    subscriptionStatus: "Inactive",
    paymentStatus: "Unpaid",
  },
  {
    id: 3,
    user: "Bob Johnson",
    email: "bob.johnson@example.com",
    subscriptionType: "Premium",
    startDate: "2024-03-01",
    endDate: "2024-08-31",
    transactionDate: "2024-01-21T09:15:00.000Z",
    transactionAmount: "$59.99",
    transactionStatus: "Successful",
    subscriptionStatus: "Active",
    paymentStatus: "Paid",
  },
  {
    id: 4,
    user: "Alice Williams",
    email: "alice.williams@example.com",
    subscriptionType: "Basic",
    startDate: "2024-05-10",
    endDate: "2024-06-10",
    transactionDate: "2024-01-22T18:20:00.000Z",
    transactionAmount: "$29.99",
    transactionStatus: "Successful",
    subscriptionStatus: "Active",
    paymentStatus: "Paid",
  },
  {
    id: 5,
    user: "Charlie Brown",
    email: "charlie.brown@example.com",
    subscriptionType: "Premium",
    startDate: "2024-04-01",
    endDate: "2024-10-01",
    transactionDate: "2024-01-23T11:45:00.000Z",
    transactionAmount: "$69.99",
    transactionStatus: "Failed",
    subscriptionStatus: "Inactive",
    paymentStatus: "Unpaid",
  },
];

export const faqs = [
  {
    id: 1,
    question: "Can any photographer sign up on Topshot Photographer?",
    answer:
      "Yes, Topshot Photographer welcomes all photographers to join our platform. To sign up, simply provide the required basic details and upload a minimum of 5 photos in your portfolio. Our team will review your profile, and upon approval, you can access our platform to apply for jobs.",
  },
  {
    id: 2,
    question: "How can I apply for jobs?",
    answer:
      "To apply for jobs, navigate to the Jobs feed where you can browse through available opportunities. Select a job that interests you and submit a proposal as a message. Your proposal will be visible to the client who posted the job. You can also track all your applied jobs in the My Jobs section.",
  },
  {
    id: 3,
    question: "How can I contact a client?",
    answer:
      "The primary way to contact a client is by applying for their job. If the client responds to your application, a chat window will be opened where you can communicate directly regarding the job.",
  },
  {
    id: 4,
    question: "How can I contact a photographer?",
    answer:
      "You can search for photographers in the Photographers feed and invite them to your posted job. If you haven't posted any jobs yet, you'll need to create one first. If the photographer is interested, they'll accept your invitation, and you can then communicate with them through our platform.",
  },
  {
    id: 5,
    question: "How can I post a job?",
    answer:
      "To post a job, sign up as a job poster and provide all the necessary job details. Once your job is posted, you can review the profiles of photographers who have applied for the job.",
  },
  {
    id: 6,
    question:
      "Is the Topshot Photographer platform free for users or are there any subscriptions?",
    answer:
      "Job posters can use the platform fully for free. For photographers, it is free with limitations in posting jobs and uploading portfolios, which you can review in our subscription plan details.",
  },
  {
    id: 7,
    question: "How can I delete my account?",
    answer:
      "You can delete your account by accessing the settings section of your profile. Once your account is deleted, your data will be permanently removed from our platform, and it cannot be restored in the future. If you have any concerns or require assistance regarding your account deletion, please don't hesitate to reach out to our support team at support@topshotphotographer.com. We're here to help you through the process and address any questions or issues you may have.",
  },
  {
    id: 7,
    question: "What part do photographers play these days in content creation?",
    answer: `Photographers play a vital role in content creation, contributing to various aspects of visual storytelling and communication. 

Here are some key parts photographers play in content creation: 

1. Visual Storytelling: 

• Photographers capture images that convey messages, evoke emotions, and tell stories. 

2. Content Marketing: 

• Photographers create visual content for marketing campaigns, social media, and advertising. 

3. Social Media: 

• Photographers produce high-quality images for social media platforms, influencer marketing, and personal branding. 

4. Editorial and Journalism: 

• Photographers contribute to news, editorial, and documentary content, providing visual context and storytelling. 

5. E-commerce and Product Photography: 

• Photographers showcase products, services, and experiences, driving online sales and engagement.

6. Branding and Identity: 

• Photographers help create visual identities for companies, individuals, and organizations. 

7. Artistic Expression: 

• Photographers create fine art, conceptual, and experimental work, pushing creative boundaries. 

8. Influencer and Lifestyle Content: 

• Photographers collaborate with influencers, capturing lifestyle, travel, and experiential content. 

9. Virtual and Augmented Reality: 

• Photographers contribute to immersive experiences, creating 360-degree and 3D content. 

10. Visual Consulting: 
• Photographers advise on visual strategy, direction, and execution for various projects and campaigns. .`,
  },
];

export const termsAndConditions = [
  {
    id: 1,
    title: "Account Registration",
    description:
      "Users must register for an account to access certain features of the platform. Users agree to provide accurate and up-to-date information during the registration process. Users are responsible for maintaining the confidentiality of their account credentials.",
  },
  {
    id: 2,
    title: "Platform Usage",
    description:
      "The platform is intended for lawful purposes only. Users agree not to interfere with the proper functioning of the platform or bypass any security measures.",
  },
  {
    id: 3,
    title: "Job Postings and Applications",
    description:
      "Job posters are responsible for the accuracy and legality of their job postings. Photographers may apply for jobs posted on the platform.",
  },
  {
    id: 4,
    title: "Subscription Plans",
    description:
      "Subscription plans are available for photographers with varying features and limitations. By subscribing to a plan, users agree to abide by the terms and conditions outlined in the subscription plan details.",
  },
  {
    id: 5,
    title: "Intellectual Property",
    description:
      "Photographers retain ownership of the content they upload to the platform. Topshot Photographer is granted a non-exclusive, royalty-free license to use, modify, and distribute the content for platform-related purposes.",
  },
  {
    id: 6,
    title: "Privacy",
    description:
      "Personal data collection and processing are conducted in accordance with the Privacy Policy. Users consent to the collection and processing of their personal data as described in the Privacy Policy by using the platform.",
  },
  {
    id: 7,
    title: "Termination",
    description:
      "Topshot Photographer reserves the right to terminate or suspend users' access to the platform for violations of the Terms or other reasons deemed appropriate.",
  },
  {
    id: 8,
    title: "Amendments",
    description:
      "Topshot Photographer may update or modify the Terms from time to time. Any changes will be effective immediately upon posting the updated Terms on the platform.",
  },
  {
    id: 9,
    title: "Governing Law",
    description:
      "The Terms are governed by and construed in accordance with the laws of the jurisdiction. Disputes arising from or relating to the Terms are subject to the exclusive jurisdiction of the courts of the jurisdiction.",
  },
  {
    id: 10,
    title: "Contact Us",
    description:
      "Users can contact Topshot Photographer for any questions or concerns regarding the Terms.",
  },
];

export const privacyPolicy = [
  {
    id: 1,
    title: "Information We Collect",
    description:
      "We collect personal information provided by users during the registration process, such as name, email address, and location. We may collect additional information automatically when users interact with the platform, including IP address, device information, and usage data.",
  },
  {
    id: 2,
    title: "Use of Information",
    description:
      "We use the information collected to provide and improve our platform's services, including facilitating job postings and applications. Personal information may also be used for communication purposes, such as sending account notifications and updates.",
  },
  {
    id: 3,
    title: "Data Sharing",
    description:
      "We may share personal information with third-party service providers who assist us in operating our platform and delivering services. Personal information may also be shared in response to legal requests or to comply with applicable laws and regulations.",
  },
  {
    id: 4,
    title: "Data Security",
    description:
      "We implement security measures to protect personal information from unauthorized access, alteration, disclosure, or destruction. Despite our efforts, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.",
  },
  {
    id: 5,
    title: "User Choices",
    description:
      "Users can review, update, or delete their personal information by accessing their account settings. Users may also opt-out of certain communications by following the instructions provided in the communication.",
  },
  {
    id: 6,
    title: "Children's Privacy",
    description:
      "Our platform is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13.",
  },
  {
    id: 7,
    title: "Updates to Privacy Policy",
    description:
      "We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on the platform.",
  },
  {
    id: 8,
    title: "Contact Us",
    description:
      "If you have any questions or concerns about this Privacy Policy, please contact us at topshotconnect@gmail.com.",
  },
];

export const aboutUsData = [
  {
    id: 1,
    title: "Our Mission",
    content:
      "At Topshot Photographer, we're on a mission to bridge the gap between photographers and clients. Our user-friendly platform fosters professional connections, ensuring quality results for every project. We strive to create an environment where photographers flourish and clients find their perfect match.",
  },
  {
    id: 2,
    title: "What We Offer",
    content: `
      For Photographers: 
          - Hiring Opportunities 
          - Portfolio Showcase 
          - Flexible Plans 
          - Client Interaction 
      For Clients: 
          - Find Photographers 
          - Post Job Listings 
          - Review and Rate 
          - Direct Communication`,
  },
  {
    id: 3,
    title: "Our Values",
    content: "- Quality - Transparency - Support - Innovation",
  },
  {
    id: 4,
    title: "Join Us Today!",
    content:
      "Whether you're a photographer eager to showcase your talents or a client seeking the perfect photographer for your project, Topshot Photographer is your destination. Join our community today and unlock the visual possibilities that await. Thank you for choosing Topshot Photographer – where every photographer deserves a chance!",
  },
  {
    id: 5,
    title: "Contact Us",
    content:
      "Have questions or need assistance? Reach out to us at topshotconnect@gmail.com or visit our Contact Us page. We're here to help!",
  },
];

export const countryCodes = [
  { code: "+1", flag: "🇺🇸", name: "USA" },
  { code: "+44", flag: "🇬🇧", name: "UK" },
  { code: "+91", flag: "🇮🇳", name: "India" },
  { code: "+61", flag: "🇦🇺", name: "Australia" },
  { code: "+49", flag: "🇩🇪", name: "Germany" },
  { code: "+33", flag: "🇫🇷", name: "France" },
  { code: "+81", flag: "🇯🇵", name: "Japan" },
  { code: "+86", flag: "🇨🇳", name: "China" },
  { code: "+55", flag: "🇧🇷", name: "Brazil" },
  { code: "+39", flag: "🇮🇹", name: "Italy" },
  { code: "+52", flag: "🇲🇽", name: "Mexico" },
  { code: "+7", flag: "🇷🇺", name: "Russia" },
  { code: "+34", flag: "🇪🇸", name: "Spain" },
  { code: "+82", flag: "🇰🇷", name: "South Korea" },
  { code: "+20", flag: "🇪🇬", name: "Egypt" },
  { code: "+971", flag: "🇦🇪", name: "UAE" },
  { code: "+27", flag: "🇿🇦", name: "South Africa" },
  { code: "+212", flag: "🇲🇦", name: "Morocco" },
  { code: "+34", flag: "🇪🇸", name: "Spain" },
  { code: "+32", flag: "🇧🇪", name: "Belgium" },
  { code: "+31", flag: "🇳🇱", name: "Netherlands" },
  { code: "+46", flag: "🇸🇪", name: "Sweden" },
  { code: "+41", flag: "🇨🇭", name: "Switzerland" },
  { code: "+90", flag: "🇹🇷", name: "Turkey" },
  { code: "+66", flag: "🇹🇭", name: "Thailand" },
  { code: "+84", flag: "🇻🇳", name: "Vietnam" },
  { code: "+64", flag: "🇳🇿", name: "New Zealand" },
  { code: "+965", flag: "🇰🇼", name: "Kuwait" },
  { code: "+974", flag: "🇶🇦", name: "Qatar" },
  { code: "+961", flag: "🇱🇧", name: "Lebanon" },
  { code: "+972", flag: "🇮🇱", name: "Israel" },
  { code: "+254", flag: "🇰🇪", name: "Kenya" },
  { code: "+255", flag: "🇹🇿", name: "Tanzania" },
  { code: "+234", flag: "🇳🇬", name: "Nigeria" },
  { code: "+62", flag: "🇮🇩", name: "Indonesia" },
  { code: "+63", flag: "🇵🇭", name: "Philippines" },
  { code: "+60", flag: "🇲🇾", name: "Malaysia" },
  { code: "+65", flag: "🇸🇬", name: "Singapore" },
  { code: "+251", flag: "🇪🇹", name: "Ethiopia" },
  { code: "+260", flag: "🇿🇲", name: "Zambia" },
  { code: "+263", flag: "🇿🇼", name: "Zimbabwe" },
  { code: "+505", flag: "🇳🇮", name: "Nicaragua" },
  { code: "+507", flag: "🇵🇦", name: "Panama" },
  { code: "+593", flag: "🇪🇨", name: "Ecuador" },
  { code: "+598", flag: "🇺🇾", name: "Uruguay" },
  { code: "+595", flag: "🇵🇾", name: "Paraguay" },
  { code: "+57", flag: "🇨🇴", name: "Colombia" },
  { code: "+56", flag: "🇨🇱", name: "Chile" },
  { code: "+591", flag: "🇧🇴", name: "Bolivia" },
  { code: "+53", flag: "🇨🇺", name: "Cuba" },
  { code: "+355", flag: "🇦🇱", name: "Albania" },
  { code: "+43", flag: "🇦🇹", name: "Austria" },
  { code: "+372", flag: "🇪🇪", name: "Estonia" },
  { code: "+358", flag: "🇫🇮", name: "Finland" },
  { code: "+420", flag: "🇨🇿", name: "Czech Republic" },
  { code: "+48", flag: "🇵🇱", name: "Poland" },
  { code: "+385", flag: "🇭🇷", name: "Croatia" },
  { code: "+421", flag: "🇸🇰", name: "Slovakia" },
  { code: "+36", flag: "🇭🇺", name: "Hungary" },
  { code: "+40", flag: "🇷🇴", name: "Romania" },
  { code: "+380", flag: "🇺🇦", name: "Ukraine" },
  { code: "+995", flag: "🇬🇪", name: "Georgia" },
  { code: "+375", flag: "🇧🇾", name: "Belarus" },
  { code: "+372", flag: "🇪🇪", name: "Estonia" },
  { code: "+374", flag: "🇦🇲", name: "Armenia" },
  { code: "+92", flag: "🇵🇰", name: "Pakistan" },
  { code: "+93", flag: "🇦🇫", name: "Afghanistan" },
  { code: "+98", flag: "🇮🇷", name: "Iran" },
  { code: "+964", flag: "🇮🇶", name: "Iraq" },
  { code: "+962", flag: "🇯🇴", name: "Jordan" },
  { code: "+968", flag: "🇴🇲", name: "Oman" },
  { code: "+963", flag: "🇸🇾", name: "Syria" },
  { code: "+260", flag: "🇿🇲", name: "Zambia" },
  { code: "+375", flag: "🇧🇾", name: "Belarus" },
];
