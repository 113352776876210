import React, { useState, useEffect } from "react";
import ImageSlider from "../../../shared/ImageSlider/ImageSlider";
import { useLocation, useSearchParams } from "react-router-dom/dist/index.js";
import { Link } from "react-router-dom";
import Filters from "../../../shared/Filters/Filters";
import { selectAccessToken } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import { photographersList } from "../../../API/PhotographerApi";
import { ErrorToaster } from "../../../shared/Toasters/toaster";
import Loader from "../../../utils/Loader";
import { EmptyComponent } from "../../../shared/Empty/Empty";
import { InformationModal } from "../../../shared/Modal/Modal";
import { Status } from "../../../constants/constant";
import HiddenFilterBar from "../../../shared/HiddenFilterBar/HiddenFilterBar";
import MapComponent from "../../../shared/MapComponent/MapComponent";
import { useForm } from "react-hook-form";
import { UseIsDesktopOrLaptop } from "../../../utils/Responsive/Responsive";
import AllFilterActionButton from "../../../shared/AllFilterActionButton/AllFilterActionButton";
import { getToken } from "../../../common/getToken";
import { AdditionalInfoRenderer } from "../../../utils/AdditionalInfoRenderer";

const PhotographersListing = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  // Extract the 'category' parameter from the search parameters
  const category = searchParams.get("category");
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [photographers, setPhotographers] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterRateType, setFilterRateType] = useState("");
  const [filterLevel, setFilterLevel] = useState("");
  const [filterPrice, setFilterPrice] = useState(null);
  const [filterLocation, setFilterLocation] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const token = useSelector(selectAccessToken) || getToken();
  const { register, setValue } = useForm();

  const searchedLocation = location?.state?.location;

  let timerId;

  const getPhotographersList = async () => {
    setStatus(Status.PENDING);
    try {
      const filterData = {
        level: filterLevel ? filterLevel.toLowerCase() : "",
        rateType: filterRateType || "",
        maxRate: filterPrice?.max,
        minRate: filterPrice?.min,
        location: filterLocation || searchedLocation,
      };

      if (filterSkills && filterSkills?.length > 0) {
        filterData.skills = filterSkills;
      }
      if (isInitialRender && !!category) {
        filterData.skills = [category];
        setIsInitialRender(false);
      }

      const filteredFilterData = Object.fromEntries(
        Object.entries(filterData).filter(([_, value]) => value !== "")
      );
      // Clear any previous timers to avoid multiple API calls
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(async () => {
        const data = await photographersList(filteredFilterData);
        setPhotographers(data || []);
        setStatus(Status.IDLE);
      }, 500); // Adjust the delay (e.g., 500ms) as per your requirement
    } catch (error) {
      console.error("error", error?.response?.data?.message);
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  useEffect(() => {
    getPhotographersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterLevel,
    filterPrice,
    filterRateType,
    filterSkills,
    filterLocation,
    searchedLocation,
  ]);

  const getAllMediaForPhotographer = (portfolioData) => {
    if (filterSkills.length > 0) {
      return portfolioData
        .filter((portfolio) =>
          filterSkills?.some((skill) =>
            portfolio?.category?.toLowerCase()?.includes(skill?.toLowerCase())
          )
        )
        .flatMap((portfolio) => portfolio.media);
    } else {
      return portfolioData.flatMap((portfolio) => portfolio.media);
    }
  };

  return (
    <>
      {showSideBar && (
        <HiddenFilterBar
          setShowSideBar={setShowSideBar}
          showSideBar={showSideBar}
          setFilterSkills={setFilterSkills}
          setFilterPrice={setFilterPrice}
          setFilterLevel={setFilterLevel}
          type="photographers"
        />
      )}
      <div>
        <InformationModal show={modalShow} onHide={() => setModalShow(false)} />
        <div className="hiddenbar-body-ovelay"></div>
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          <section className="breadcumb-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcumb-style1">
                    <div className="breadcumb-list">
                      <Link to="/photographers">Home</Link>
                      <Link to="/photographers">Photographers</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Listings All Lists --> */}
          <section className="pt30 pb90">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <Filters
                    setFilterSkills={setFilterSkills}
                    filterSkills={filterSkills}
                    setFilterPrice={setFilterPrice}
                    setFilterLevel={setFilterLevel}
                    // setFilterRateType={setFilterRateType}
                    setFilterLocation={setFilterLocation}
                    type="photographers"
                  />
                </div>
                <div className="col-lg-9">
                  <div className="row align-items-center mb20">
                    <div className="col-md-6">
                      <div className="text-center text-md-start">
                        <p className="text mb-0 mb10-sm">
                          <span className="fw500">
                            ({photographers.length})
                          </span>{" "}
                          <span className="fz16">Photographers available</span>
                        </p>
                      </div>
                    </div>
                    {!isDesktopOrLaptop && (
                      <div className="col-md-6 col-lg-12">
                        <div className=" p-0 mb-0 text-center">
                          <div className="search_area mb15 mr10">
                            <MapComponent
                              name="filterLocation"
                              register={register}
                              setValue={setValue}
                              setFilterLocation={setFilterLocation}
                              placeholder="Search by location..."
                            />
                            <label>
                              <span className="flaticon-loupe"></span>
                            </label>
                          </div>
                          <AllFilterActionButton
                            handleClick={() => setShowSideBar((prev) => !prev)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {status === Status.PENDING ? (
                    <Loader />
                  ) : (
                    <div className="row">
                      {photographers?.map((photographer) => (
                        <div
                          className="col-md-6 col-lg-12"
                          key={photographer._id}
                        >
                          <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 bdrs12">
                            <div className="col-lg-12 p-0">
                              <div className="d-lg-flex align-items-center justify-content-lg-between mb-3">
                                <div>
                                  <div className="d-lg-flex align-items-center">
                                    <div className="thumb w90 position-relative rounded-circle mb-3 mb-lg-0">
                                      <img
                                        className="rounded-circle mx-auto"
                                        style={{
                                          width: "100px",
                                          height: "100px", // Set a fixed height
                                          objectFit: "cover", // Maintain aspect ratio and cover the container
                                          borderRadius: "50%", // Make the image round by setting border radius to 50%
                                        }}
                                        src={
                                          photographer?.profilePicture ||
                                          "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                        }
                                        alt="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                                      />
                                      {/* <span className="online"></span> */}
                                    </div>
                                    <div className="ml20 ml0-md">
                                      <h5 className="title mb-1">
                                        {photographer?.fullName}
                                      </h5>
                                      {/* <p className="mb-0">UI/UX Designer</p> */}
                                      <p className="mb-0 fz16">
                                        {photographer?.title}
                                      </p>
                                      <div className="review mb5">
                                        <p className="list-inline-item">
                                          <i className="fas fa-star fz10 review-color pr10"></i>
                                          <span className="dark-color fw500">
                                            {photographer?.rating || 0}
                                          </span>
                                        </p>
                                        <p className="list-inline-item">
                                          <i className="flaticon-place fz16 dark-color pr10"></i>
                                          <span className="dark-color fw500">
                                            {photographer?.location || "NA"}
                                          </span>
                                        </p>
                                        <p className="list-inline-item">
                                          <i className="flaticon-income fz16 dark-color pr5"></i>
                                          <span className="dark-color fw500 fz16">
                                            $
                                            {photographer?.photographerDetails
                                              ?.hourlyRate || 0}{" "}
                                            / hr
                                          </span>
                                        </p>
                                        <p className="list-inline-item">
                                          <i className="fa fa-level-up pr10"></i>
                                          <span className="dark-color fw500  text-capitalize">
                                            {photographer?.photographerDetails
                                              ?.level || "NA"}{" "}
                                          </span>
                                        </p>
                                        <p className="list-inline-item">
                                          <i className="fas fa-history pr10"></i>
                                          <span className="dark-color fw500">
                                            {photographer?.photographerDetails
                                              ?.experience || 0}{" "}
                                            years
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {!!token ? (
                                  <div>
                                    <Link
                                      to={`/photographers/${photographer._id}`}
                                      target="_blank"
                                      className="ud-btn btn-thm-border hover-default-box-shadow1"
                                    >
                                      View Profile
                                      <i className="fal fa-arrow-right-long"></i>
                                    </Link>
                                  </div>
                                ) : (
                                  <button
                                    className="ud-btn btn-thm-border hover-default-box-shadow1"
                                    onClick={() => setModalShow(true)}
                                  >
                                    View Profile
                                    <i className="fal fa-arrow-right-long"></i>
                                  </button>
                                )}
                              </div>
                              <div className="skill-tags d-flex flex-wrap align-items-center mb-0">
                                {photographer?.photographerDetails?.skills.map(
                                  (skill, i) => (
                                    <span className="tag mx5 my-2" key={i}>
                                      {skill}
                                    </span>
                                  )
                                )}
                              </div>
                              <p className="text fz15">
                                {" "}
                                <AdditionalInfoRenderer
                                  text={photographer?.description}
                                  maxLength={150}
                                />
                              </p>
                              <div className="col-lg-12 mt25">
                                <ImageSlider
                                  images={getAllMediaForPhotographer(
                                    photographer?.portfolioData
                                  )}
                                  isPhotographer={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {photographers.length === 0 && status === Status.IDLE && (
                    <div className="mt90">
                      <EmptyComponent description="No Data Found." />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PhotographersListing;
