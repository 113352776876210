import React, { useCallback, useMemo } from "react";
import { formatDate } from "../../utils/Dates";
import Loader from "../../utils/Loader";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import { useNavigate } from "react-router-dom";
// import debounce from "lodash.debounce"; // Optional: install this to debounce resize

const NotificationComponent = ({
  showNotificationDropdown,
  notifications,
  isLoading,
}) => {
  const navigate = useNavigate();
  // const [translateXPercentage, setTranslateXPercentage] = useState(235);
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();

  const handleNotificationClick = useCallback(
    (redirectURL) => {
      navigate(`/${redirectURL}`);
    },
    [navigate]
  );

  // const updateTranslateX = useMemo(() => {
  //   const screenWidth = window.innerWidth;
  //   const widthPercentageMap = {
  //     1366: 235,
  //     1517: 275,
  //     1707: 320,
  //     1821: 350,
  //   };
  //   return widthPercentageMap[screenWidth] || 405;
  // }, []);

  // useEffect(() => {
  //   const debouncedUpdate = debounce(() => {
  //     setTranslateXPercentage(updateTranslateX);
  //   }, 200);

  //   window.addEventListener("resize", debouncedUpdate);
  //   return () => {
  //     window.removeEventListener("resize", debouncedUpdate);
  //   };
  // }, [updateTranslateX]);

  const dropdownStyle = useMemo(
    () => ({
      width: "395px",
      margin: isDesktopOrLaptop ? "20px -80px" : "0px 0px",
      marginTop: isDesktopOrLaptop ? "20px" : "590px",
    }),
    [isDesktopOrLaptop]
  );

  const notificationsStyle = useMemo(
    () => ({
      maxHeight: "600px",
      overflowY: "auto",
      paddingRight: isDesktopOrLaptop ? "" : "20px",
    }),
    [isDesktopOrLaptop]
  );

  return (
    <div
      className={`dropdown-menu ${showNotificationDropdown ? "show" : ""}`}
      style={dropdownStyle}
    >
      {isLoading ? (
        <Loader />
      ) : notifications.length > 0 ? (
        <div
          className={`dboard_notific_dd ${
            isDesktopOrLaptop ? "px30" : ""
          } pt10 pb15`}
          style={notificationsStyle}
        >
          {notifications.map((notification) => (
            <div
              key={notification?._id}
              className="notif_list d-flex align-items-center justify-content-between bdrb1 pb15 mb10"
              style={{ cursor: "pointer" }}
              onClick={() => handleNotificationClick(notification?.redirectURL)}
            >
              <img
                src="https://creativelayers.net/themes/freeio-html/images/resource/notif-4.png"
                alt="Notification Icon"
              />
              <div className="details d-flex flex-column">
                <p className="text mb-0 ml20 fz15">{notification?.message}</p>
                <span
                  className="fz12"
                  style={{
                    textAlign: "right",
                    height: isDesktopOrLaptop ? "" : "40px",
                  }}
                >
                  {notification?.createdDate &&
                    formatDate(notification?.createdDate)}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-17 text-center text-info py-3">
          No new notifications
        </div>
      )}
    </div>
  );
};

// Wrap the component with React.memo to prevent unnecessary re-renders
export default React.memo(NotificationComponent);
