import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { photographerDetails } from "../../../API/PhotographerApi";
import { ErrorToaster } from "../../../shared/Toasters/toaster";
import Loader from "../../../utils/Loader";
import { formatDate } from "../../../utils/Dates";
import { EmptyComponent } from "../../../shared/Empty/Empty";
import ImageGrid from "./Portfolio/ImageGrid";
import ReviewComponent from "../../../shared/Review/ReviewComponent";
import { getUserInfo } from "../../../common/getUserInfo";
import { userDetails } from "../../../API/AdminApi";
import { UserTypes } from "../../../constants/constant";
import VerifyIcon from "../../../utils/Icons/verify";
import StudioSpaceIcon from "../../../utils/Icons/StudioSpaceIcon";
import InsuranceIcon from "../../../utils/Icons/InsuranceIcon";
import JobInviteModal from "../../../components/model/InviteModal";
import { UseIsDesktopOrLaptop } from "../../../utils/Responsive/Responsive";

const PhotographerDetails = () => {
  const { id } = useParams();
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [photographerInfo, setPhotographerInfo] = useState(null);
  const [activeTab, setActiveTab] = useState("portfolio");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const userInfo = getUserInfo();
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const getPhotographerDetails = async () => {
    setStatus("loading");
    try {
      const data =
        userInfo?.userType === "admin"
          ? await userDetails({ userId: id, userType: UserTypes.PHOTOGRAPHER })
          : await photographerDetails(id);
      setPhotographerInfo(data || null);
      setStatus("idle");
    } catch (error) {
      setStatus("rejected");
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };
  useEffect(() => {
    getPhotographerDetails();
  }, [id]);

  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleClick = (category) => {
    setSelectedCategory(category);
  };

  const getAllMediaForPhotographer = (portfolioData) => {
    if (selectedCategory === "All") {
      // If 'All' is selected, display all media
      return portfolioData?.flatMap((portfolio) => portfolio.media);
    } else {
      // If any other category is selected, filter and display media for that category
      const filteredMedia = portfolioData
        ?.filter((portfolio) => portfolio.category === selectedCategory)
        .flatMap((portfolio) => portfolio.media);
      return filteredMedia;
    }
    // return portfolioData?.flatMap((portfolio) => portfolio.media)
  };

  const handleContactMeClick = () => {
    setShowInviteModal(true);
  };

  return (
    <>
      {showInviteModal && (
        <JobInviteModal
          showInviteModal={showInviteModal}
          setShowInviteModal={setShowInviteModal}
          photographerId={photographerInfo?._id}
        />
      )}
      <div className="body_content">
        {!userInfo?.userType === "admin" && (
          <section className="breadcumb-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-8 col-lg-10">
                  <div className="breadcumb-style1 mb10-xs">
                    <div className="breadcumb-list">
                      <Link to="/">Home</Link>
                      <Link to="/photographers">Photographers</Link>
                      <Link to={`/photographers/${id}`}>
                        Photographer Detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {status === "loading" ? (
          <Loader />
        ) : (
          <section className="pt40 pb90 pb30-md">
            <div className="container">
              <div className="row wrap wow fadeInUp">
                <div className="col-lg-8">
                  <div className="column">
                    <div className="cta-service-v1 freelancer-single-v1 pt60 pb60 bdrs16 position-relative overflow-hidden d-flex align-items-center">
                      <img
                        className="left-top-img wow zoomIn"
                        src="images/vector-img/left-top.png"
                        alt=""
                      />
                      <img
                        className="right-bottom-img wow zoomIn"
                        src="images/vector-img/right-bottom.png"
                        alt=""
                      />
                      <div className="row wow fadeInUp">
                        <div className="col-xl-12">
                          <div className="position-relative pl50 pl20-sm">
                            <div className="list-meta d-sm-flex align-items-center">
                              <div className="thumb w90 position-relative rounded-circle mb-3 mb-lg-0">
                                <img
                                  className="rounded-circle mx-auto"
                                  style={{
                                    width: "85px",
                                    height: "85px", // Set a fixed height
                                    objectFit: "cover", // Maintain aspect ratio and cover the container
                                    borderRadius: "50%", // Make the image round by setting border radius to 50%
                                  }}
                                  src={
                                    photographerInfo?.profilePicture ||
                                    "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                  }
                                  alt="Profile pic"
                                />
                                <span className="online"></span>
                              </div>

                              <div className="ml20 ml0-xs">
                                <h5 className="title mb-1">
                                  {photographerInfo?.fullName}
                                </h5>
                                {/* <p className="mb-0">UI/UX Designer</p> */}
                                <p className="mb-0">
                                  {photographerInfo?.title}
                                </p>
                                {/* <p className="mb-0 dark-color fz15 fw500 list-inline-item mb5-sm">
                                <i className="fas fa-star vam fz10 review-color me-2"></i>{' '}
                                {photographerInfo?.totalReview || 0} reviews
                              </p> */}
                                {/*<p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">*/}
                                {/*  <i className="flaticon-place vam fz20 me-2"></i>{' '}*/}
                                {/*  {photographerInfo?.photographerDetails*/}
                                {/*    ?.location || 'London, UK'}*/}
                                {/*</p>*/}
                                {/*<p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">*/}
                                {/*  <i className="flaticon-30-days vam fz20 me-2"></i>{' '}*/}
                                {/*  Member since:{' '}*/}
                                {/*  {formatDate(*/}
                                {/*    photographerInfo?.creationDate ||*/}
                                {/*      '2023-11-07T17:25:35.030Z'*/}
                                {/*  )}*/}
                                {/*</p>*/}

                                {/* <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                                <i className="fa fa-level-up pr10 vam fz10 review-color me-2"></i>{' '}
                                Level:{' '}
                                {photographerInfo?.photographerDetails?.level ||
                                  'NA'}
                                {}
                              </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt30">
                      <div className="col-sm-6 col-xl-3">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-target"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Reviews</h5>
                            <p className="mb-0 text">
                              {photographerInfo?.rating || 0} (
                              {photographerInfo?.totalReview || 0} reviews)
                              {/* {photographerInfo?.jobSuccess || 0}% */}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-goal"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Total Jobs</h5>
                            <p className="mb-0 text">
                              {photographerInfo?.totalJobCount || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-fifteen"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Level</h5>
                            <p className="mb-0 text text-capitalize">
                              {photographerInfo?.photographerDetails?.level ||
                                "NA"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-file-1"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Years In Business</h5>
                            <p className="mb-0 text">
                              {photographerInfo?.photographerDetails
                                ?.experience || 0}{" "}
                              years
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-about">
                      <h4>Description</h4>
                      <p className="text mb60">
                        {photographerInfo?.bio || (
                          <EmptyComponent description="No Data." type={true} />
                        )}
                      </p>
                      {userInfo?._id !== photographerInfo?._id &&
                        !isDesktopOrLaptop && (
                          <div className="d-grid">
                            <button
                              className="ud-btn btn-thm"
                              onClick={handleContactMeClick}
                            >
                              Contact<i class="fal fa-arrow-right-long"></i>
                            </button>
                          </div>
                        )}
                      {/* <hr className="opacity-100 mb60 mt60" /> */}
                      <div
                        className="navtab-style1"
                        style={{
                          width: isDesktopOrLaptop ? "1120px" : "100%",
                        }}
                      >
                        <nav>
                          <div className="nav nav-tabs mb60" role="tablist">
                            <button
                              className={`nav-link ${
                                activeTab === "portfolio"
                                  ? "active fw500 ps-0"
                                  : "fw500"
                              }`}
                              type="button"
                              onClick={() => handleTabClick("portfolio")}
                            >
                              Portfolio
                            </button>
                            <button
                              className={`nav-link ${
                                activeTab === "reviews"
                                  ? "active fw500"
                                  : "fw500"
                              }`}
                              type="button"
                              onClick={() => handleTabClick("reviews")}
                            >
                              Reviews
                            </button>
                            {/* Add more buttons for other tabs if needed */}
                          </div>
                        </nav>
                        <div
                          className="tab-content"
                          style={{ marginTop: "-45px" }}
                        >
                          {activeTab === "portfolio" &&
                            (photographerInfo?.portfolioData?.length > 0 ? (
                              <div>
                                <div className="skill-tags border-black d-flex flex-wrap mb10">
                                  <span
                                    className="tag badge mr10"
                                    style={{
                                      backgroundColor:
                                        selectedCategory === "All"
                                          ? "#5BBB7B"
                                          : "#ffffff",
                                      color:
                                        selectedCategory === "All"
                                          ? "#ffffff"
                                          : "",
                                      border: "1px solid #5BBB7B",
                                    }}
                                    onClick={() => handleClick("All")}
                                  >
                                    All
                                  </span>
                                  {photographerInfo?.portfolioData?.map(
                                    (portfolio, index) => (
                                      <span
                                        key={index}
                                        className="tag badge mr10 mt5"
                                        style={{
                                          backgroundColor:
                                            selectedCategory ===
                                            portfolio?.category
                                              ? "#5BBB7B"
                                              : "#ffffff",
                                          color:
                                            selectedCategory ===
                                            portfolio?.category
                                              ? "#ffffff"
                                              : "",
                                          border: "1px solid #5BBB7B",
                                        }}
                                        onClick={() =>
                                          handleClick(portfolio?.category)
                                        }
                                      >
                                        {portfolio?.category}
                                      </span>
                                    )
                                  )}
                                </div>
                                <ImageGrid
                                  images={getAllMediaForPhotographer(
                                    photographerInfo?.portfolioData
                                  )}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  marginRight: "50px",
                                  alignItems: "center",
                                }}
                              >
                                <EmptyComponent
                                  description="No Data."
                                  type={true}
                                />
                              </div>
                            ))}
                          {activeTab === "reviews" &&
                            photographerInfo?.reviews.length > 0 && (
                              <ReviewComponent
                                reviews={photographerInfo?.reviews}
                                reviewersDetails={
                                  photographerInfo?.reviewUsersDetail
                                }
                                reviewJobs={photographerInfo?.reviewJobsDetail}
                                type="photographer"
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="column">
                    <div className="blog-sidebar ms-lg-auto">
                      <div className="price-widget pt25 bdrs12 default-box-shadow1s">
                        <h3 className="widget-title">
                          ${" "}
                          {photographerInfo?.photographerDetails?.hourlyRate ||
                            0}{" "}
                          <small className="fz15 fw500">/per hour</small>
                        </h3>
                        <div className="category-list mt20">
                          <div
                            className="d-flex align-items-center justify-content-between bdrb1 pb-2"
                            href=""
                          >
                            <span className="text fz16 m5">
                              <i className="flaticon-place text-thm2 pe-2 vam"></i>
                              Location
                            </span>{" "}
                            <span className="Name">
                              {photographerInfo?.location || "-"}
                            </span>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between bdrb1 pb-2"
                            href=""
                          >
                            <span className="text fz16 m5">
                              <i className="flaticon-30-days text-thm2 pe-2 vam"></i>
                              Member since
                            </span>{" "}
                            <span className="Name">
                              {formatDate(
                                photographerInfo?.creationDate ||
                                  "2023-11-07T17:25:35.030Z"
                              )}
                            </span>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between bdrb1 pb-2"
                            href=""
                          >
                            <span className="text fz16 m5">
                              <StudioSpaceIcon /> Do you offer studio space?
                            </span>{" "}
                            <span className="Name">
                              {photographerInfo?.isStudioSpaceAvailable
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between bdrb1 pb-2"
                            href=""
                          >
                            <span className="text fz16 m5">
                              <InsuranceIcon /> Do you have business insurance?
                            </span>{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {photographerInfo?.isBusinessInsured
                                    ? "This photographer has verified business insurance."
                                    : "This photographer has not verified business insurance."}
                                </Tooltip>
                              }
                            >
                              <div style={{ marginRight: "-20px" }}>
                                <VerifyIcon
                                  isVerified={
                                    photographerInfo?.isBusinessInsured
                                  }
                                />
                              </div>
                            </OverlayTrigger>
                            <span className="Name ml25">
                              {photographerInfo?.isBusinessInsured
                                ? "Yes"
                                : "No"}{" "}
                            </span>
                          </div>
                        </div>

                        {userInfo?._id !== photographerInfo?._id &&
                          isDesktopOrLaptop && (
                            <div className="d-grid">
                              <button
                                className="ud-btn btn-thm"
                                onClick={handleContactMeClick}
                              >
                                Contact<i class="fal fa-arrow-right-long"></i>
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="sidebar-widget mb30 pb20 bdrs12 default-box-shadow1s">
                        <h4 className="widget-title">My Skills</h4>
                        <div className="tag-list mt30">
                          {photographerInfo?.photographerDetails?.skills.map(
                            (skill, i) => (
                              <span className="tag mx5 my-2" key={i}>
                                {skill}
                              </span>
                            )
                          )}
                          {photographerInfo?.photographerDetails?.skills
                            .length === 0 && (
                            <EmptyComponent
                              description="No Data."
                              type={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default PhotographerDetails;
