import React, { useEffect, useState } from "react";
import { getAllChat, getChatCount } from "../../../../API/ChatApi";
import { Status } from "../../../../constants/constant";
import Loader from "../../../../utils/Loader";
import { ErrorToaster } from "../../../../shared/Toasters/toaster";
import { getUserInfo } from "../../../../common/getUserInfo";
import ChatBox from "./ChatBox";
import { useLocation } from "react-router-dom";
import { formatTimeFromNow } from "../../../../utils/Dates";
import { EmptyComponent } from "../../../../shared/Empty/Empty";
import { UseIsDesktopOrLaptop } from "../../../../utils/Responsive/Responsive";

const MyMessages = (props) => {
  const [chatList, setChatList] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [status, setStatus] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [jobDetail, setJobDetail] = useState(null);
  const [chatCount, setChatCount] = useState(null);
  const userInfo = getUserInfo();
  const location = useLocation();
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();

  const searchParams = new URLSearchParams(location.search);
  const chatId = searchParams.get("q");
  useEffect(() => {
    setCurrentChatId(chatId);
  }, [chatId]);

  const getAllChatList = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getAllChat();
      setChatList(res?.chatList || []);
      setJobData(res?.jobData || {});
      setUserData(res?.userData || {});
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const getChatsCount = async () => {
    try {
      const res = await getChatCount();
      setChatCount(res || {});
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  useEffect(() => {
    getChatsCount();
    const interval = setInterval(() => {
      getChatsCount();
    }, 60000); // 6000 milliseconds = 1 minute

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getAllChatList();
  }, []);

  const handleItemClick = async (chatId) => {
    setCurrentChatId(chatId);
    const url = new URL(window.location.href);
    url.searchParams.set("q", chatId); // Replace 'q' with your query parameter key

    window.history.replaceState(null, "", url);
    setShowChat(true);
  };

  function limitText(text, maxLength = 20) {
    if (!text) return ""; // Handle case where text is null or undefined
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div className="mt30">
      <div className="row mb40" style={{ margin: "-25px", height: "70%" }}>
        <div className="col-lg-6 col-xl-5 col-xxl-4">
          <div className="message_container">
            <div
              className="inbox_user_list"
              // style={{ background: 'azure' }}
            >
              <div className="iu_heading pr35">
                <div className="chat_user_search">
                  <form className="d-flex align-items-center">
                    <button className="btn" type="submit">
                      <span className="far fa-magnifying-glass"></span>
                    </button>
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                  </form>
                </div>
              </div>
              {status === Status.PENDING ? (
                <div style={{ marginRight: "150px" }}>
                  <Loader />
                </div>
              ) : (
                <div className="chat-member-list pr20">
                  {chatList?.map((chat) => (
                    <div
                      className="list-item"
                      style={{
                        backgroundColor:
                          chat?.chatId === currentChatId
                            ? "rgb(183 244 203)"
                            : "transparent",
                        borderRadius:
                          chat?.chatId === currentChatId ? "5px" : "",
                        /* Add other styles here */
                      }}
                      key={chat?._id}
                      onClick={() => handleItemClick(chat?.chatId)}
                    >
                      <div className="d-flex align-items-center position-relative">
                        <img
                          className=" mr10"
                          src={
                            userInfo?.userType === "photographer"
                              ? userData[chat?.senderId]?.profilePicture ||
                                "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                              : userData[chat?.receiverId]?.profilePicture ||
                                "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                          }
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            borderRadius: "50%",
                            marginLeft: "5px",
                          }}
                          // src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                          alt="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                        />
                        <div className="d-sm-flex">
                          <div className="d-inline-block">
                            <div className="fz15 fw500 dark-color ff-heading mb-0">
                              {userInfo?.userType === "photographer"
                                ? limitText(userData[chat?.senderId]?.fullName)
                                : limitText(
                                    userData[chat?.receiverId]?.fullName
                                  )}
                            </div>
                            <p className="preview">
                              {limitText(jobData[chat?.jobId])}
                            </p>
                          </div>
                          <div className="iul_notific">
                            <small
                              style={{
                                top: isDesktopOrLaptop ? "44px" : "15px",
                              }}
                            >
                              {formatTimeFromNow(
                                chat?.lastMessageTime || new Date()
                              )}
                            </small>
                            {chatCount?.[chat?.chatId] && (
                              <div className="m_notif online">
                                {chatCount?.[chat?.chatId]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {chatList?.length === 0 && (
                    <div
                      style={{
                        marginRight: "50px",
                        alignItems: "center",
                      }}
                    >
                      <EmptyComponent
                        description="No Conversation found."
                        type={true}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {currentChatId || showChat ? (
          <ChatBox
            chatId={currentChatId || chatId}
            jobDetail={jobDetail}
            userData={userData}
          />
        ) : (
          <div
            style={{
              marginLeft: "415px",
              marginTop: "150px",
              position: "absolute",
              width: "50%",
            }}
          >
            <EmptyComponent description="No Chat found." type={true} />
          </div>
        )}
        {/* {!showChat && (
          <div
            style={{
              marginRight: '50px',
              alignItems: 'center',
            }}>
            <EmptyComponent description="No Conversation found." type={true} />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default MyMessages;
