import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/Dates";
import { ErrorToaster } from "../../../../shared/Toasters/toaster";
import Loader from "../../../../utils/Loader";
import { Status } from "../../../../constants/constant";
import { jobInvitations } from "../../../../API/UserApi";
import { AdditionalInfoRenderer } from "../../../../utils/AdditionalInfoRenderer";

const JobInvites = () => {
  const [jobInvites, setJobInvites] = useState([]);
  const [status, setStatus] = useState("");

  const myJobInvites = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await jobInvitations();
      setJobInvites(res || []);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  useEffect(() => {
    myJobInvites();
  }, []);

  console.log("jobInvites", jobInvites);

  return status === Status.PENDING ? (
    <Loader />
  ) : (
    <div className="row">
      {jobInvites?.invitations?.map(({ jobId, invitationDate }) => {
        const {
          title,
          budgetType,
          category,
          description,
          durationType,
          jobDateTime,
          jobDuration,
          jobEndDateTime,
          jobRate,
        } = jobInvites?.jobDetails[jobId] || {};
        return (
          <div className="col-md-6 col-lg-12">
            <div className="freelancer-style1 bdr1 bdrs12 hover-box-shadow row ms-0 align-items-lg-center">
              <div className="col-lg-8 ps-0 bdrr1 bdrn-xl">
                <div className="d-lg-flex">
                  <div className="thumb w60 position-relative rounded-circle mb15-md">
                    <img
                      className="rounded-circle mx-auto"
                      src="images/team/client-1.png"
                      alt=""
                    />
                    <span className="online-badge2"></span>
                  </div>
                  <div className="details ml15 ml0-md mb15-md">
                    <h5 className="title mb-3">{title}</h5>
                    <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                      <i className="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                      London, UK
                    </p>
                    <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                      <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                      {formatDate(invitationDate)}
                    </p>
                    <p className="mb-0 fz14 list-inline-item mb5-sm">
                      <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                      {jobDuration} {durationType}
                    </p>
                    <p className="mb-0 fz14 list-inline-item mb5-sm">
                      <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                      {formatDate(jobDateTime)} - {formatDate(jobEndDateTime)}
                    </p>
                  </div>
                </div>
                <p className="text mt10">
                  <AdditionalInfoRenderer text={description} maxLength={250} />
                </p>
                {/* <div className="skill-tags d-flex align-items-center justify-content-start mb20-md">
                  <span className="tag">Figma</span>
                  <span className="tag mx10">Sketch</span>
                  <span className="tag">HTML5</span>
                </div> */}
              </div>
              <div className="col-lg-4 ps-0 ps-xl-3 pe-0">
                <div className="details">
                  <div className="text-lg-end">
                    <h4>${jobRate}</h4>
                    <p className="text">{budgetType} Rate</p>
                  </div>
                  <div className="d-grid mt15">
                    <Link
                      to={`/jobs/${jobId}`}
                      target="_blank"
                      className="ud-btn btn-thm-border bdrs12 hover-default-box-shadow1"
                    >
                      View Details<i className="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobInvites;
